import { appActionBarHelpCenterIcon } from './action-bar-help-center';
import { appActionBarNotificationsIcon } from './action-bar-notifications';
import { appAppsIcon } from './apps';
import { appBillingIcon } from './billing';
import { appCustomerSuccessManagerIcon } from './customer-success-manager';
import { appDetailIcon } from './detail';
import { appErrorSolidIcon } from './error-solid';
import { appHammerIcon } from './hammer';
import { appKubernetesIcon } from './kubernetes';
import { appOwnerIcon } from './owner';
import { appRepairIcon } from './repair';
import { appSetDefaultIcon } from './set-default';
import { appSkullIcon } from './skull';
export const oldIcons = [appActionBarHelpCenterIcon, appActionBarNotificationsIcon, appAppsIcon, appBillingIcon, appCustomerSuccessManagerIcon, appDetailIcon, appErrorSolidIcon, appHammerIcon, appKubernetesIcon, appOwnerIcon, appRepairIcon, appSetDefaultIcon, appSkullIcon];
