import { appKey01Icon } from './key-01';
import { appKey02Icon } from './key-02';
import { appLock01Icon } from './lock-01';
import { appLock02Icon } from './lock-02';
import { appLockUnlocked01Icon } from './lock-unlocked-01';
import { appLockUnlocked02Icon } from './lock-unlocked-02';
import { appScanIcon } from './scan';
import { appShield01Icon } from './shield-01';
import { appShieldTickIcon } from './shield-tick';
export const securityIcons = [appKey01Icon, appKey02Icon, appLock01Icon, appLock02Icon, appLockUnlocked01Icon, appLockUnlocked02Icon, appScanIcon, appShield01Icon, appShieldTickIcon];
