import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, forkJoin, iif, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PartnersApiService, UserRole } from '@api-open';
import { AlertifyService, CommonService, PermissionService, UserDataService } from '@shared/services';

export const hasDataForAuthedZone: CanActivateFn = (): Observable<boolean> => {
  const userDataService = inject(UserDataService);
  const commonService = inject(CommonService);
  const permissionService = inject(PermissionService);
  const partnersApiService = inject(PartnersApiService);
  const alertify = inject(AlertifyService);

  return forkJoin([
    userDataService.loadCurrentUser(),
    commonService.getEnumValues().pipe(
      tap((values) => {
        commonService.setEnumValues(values);
      }),
    ),
  ]).pipe(
    switchMap(() =>
      iif(
        () => permissionService.check(UserRole.admin, UserRole.partner),
        partnersApiService.partnerDetails(),
        of(null),
      ),
    ),
    map(() => true),
    catchError(() => {
      alertify.negative('Some requests failed, and you can`t go inside! Reload the page and try again!');
      return of(false);
    }),
  );
};
