import { appBrowserIcon } from './browser';
import { appContainerIcon } from './container';
import { appCpuChip01Icon } from './cpu-chip-01';
import { appDataIcon } from './data';
import { appGitCommitIcon } from './git-commit';
import { appGitMergeIcon } from './git-merge';
import { appPackageIcon } from './package';
import { appServer04Icon } from './server-04';
import { appTerminalBrowserIcon } from './terminal-browser';
import { appTerminalIcon } from './terminal';
import { appVariableIcon } from './variable';
export const developmentIcons = [appBrowserIcon, appContainerIcon, appCpuChip01Icon, appDataIcon, appGitCommitIcon, appGitMergeIcon, appPackageIcon, appServer04Icon, appTerminalBrowserIcon, appTerminalIcon, appVariableIcon];
