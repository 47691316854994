import { Component, OnInit } from '@angular/core';

import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {
    if (navigator.userAgent.includes('Win')) {
      document.body.classList.add('scrollbar-win'); // see {@link '../scss/taikun/scrollbar'}
    }
  }

  ngOnInit() {
    const token = localStorage.getItem('token');
    if (token) {
      this.authService.setLoginProperties(token);
    }
  }
}
