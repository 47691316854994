import { appArrowCircleUpIcon } from './arrow-circle-up';
import { appArrowDownIcon } from './arrow-down';
import { appArrowLeftIcon } from './arrow-left';
import { appArrowNarrowLeftIcon } from './arrow-narrow-left';
import { appArrowNarrowRightIcon } from './arrow-narrow-right';
import { appArrowRightIcon } from './arrow-right';
import { appArrowUpIcon } from './arrow-up';
import { appChevronDownIcon } from './chevron-down';
import { appChevronLeftIcon } from './chevron-left';
import { appChevronRightIcon } from './chevron-right';
import { appChevronUpIcon } from './chevron-up';
import { appInfinityIcon } from './infinity';
import { appRefreshCcw02Icon } from './refresh-ccw-02';
import { appRefreshCw01Icon } from './refresh-cw-01';
import { appRefreshCw02Icon } from './refresh-cw-02';
import { appSwitchVertical01Icon } from './switch-vertical-01';
export const arrowsIcons = [appArrowCircleUpIcon, appArrowDownIcon, appArrowLeftIcon, appArrowNarrowLeftIcon, appArrowNarrowRightIcon, appArrowRightIcon, appArrowUpIcon, appChevronDownIcon, appChevronLeftIcon, appChevronRightIcon, appChevronUpIcon, appInfinityIcon, appRefreshCcw02Icon, appRefreshCw01Icon, appRefreshCw02Icon, appSwitchVertical01Icon];
