import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of } from 'rxjs';

import { UserRole } from '@api-open';

import { OrganizationsService, PermissionService } from '../services';

export const organizationsResolver: ResolveFn<boolean> = (): Observable<boolean> => {
  const organizationsService = inject(OrganizationsService);
  const permissionService = inject(PermissionService);

  if (permissionService.check(UserRole.admin, UserRole.partner)) {
    return organizationsService.init();
  }
  if (permissionService.check(UserRole.manager, UserRole.user)) {
    return organizationsService.initWithoutOrganizationSelect();
  }

  return of(true);
};
