import { appAlignLeftIcon } from './align-left';
import { appAlignRightIcon } from './align-right';
import { appAttachment01Icon } from './attachment-01';
import { appColorsIcon } from './colors';
import { appCommandIcon } from './command';
import { appDeleteIcon } from './delete';
import { appDropIcon } from './drop';
import { appDropperIcon } from './dropper';
import { appFigmaIcon } from './figma';
import { appHandIcon } from './hand';
import { appLineHeightIcon } from './line-height';
import { appMoveIcon } from './move';
import { appPaintIcon } from './paint';
import { appPaletteIcon } from './palette';
import { appSubscriptIcon } from './subscript';
import { appTransformIcon } from './transform';
export const editorIcons = [appAlignLeftIcon, appAlignRightIcon, appAttachment01Icon, appColorsIcon, appCommandIcon, appDeleteIcon, appDropIcon, appDropperIcon, appFigmaIcon, appHandIcon, appLineHeightIcon, appMoveIcon, appPaintIcon, appPaletteIcon, appSubscriptIcon, appTransformIcon];
