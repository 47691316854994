export const appAzureAKSLogoIcon = {
    data: `<svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
<path d="M89.7706 37.3251L56.0375 43.5463V89.3248L89.7706 96.485L123.622 82.9863V48.8283L89.7706 37.3251Z" fill="url(#paint0_linear_866_816)"/>
<path d="M56.0375 43.5768V89.3553L90.1245 96.5156V37.8251L56.0375 43.5768ZM70.4271 85.8339L60.8733 83.9558V48.7416L70.4271 47.2155V85.8339ZM85.2885 88.5337L74.3195 86.7729V46.5113L85.2885 44.6331V88.5337Z" fill="#341A6E"/>
<path d="M162.758 37.925L129.025 44.1462V89.9248L162.758 97.0851L196.492 83.4689V49.4284L162.758 37.925Z" fill="url(#paint1_linear_866_816)"/>
<path d="M129.025 44.1267V89.9052L162.876 97.0654V38.375L129.025 44.1267ZM143.297 86.3838L133.743 84.5057V49.2914L143.297 47.7655V86.3838ZM158.158 89.0836L147.188 87.3228V47.0612L158.158 45.0657V89.0836Z" fill="#341A6E"/>
<path d="M55.1956 100.5L21.4625 106.721V152.499L55.1956 159.66L89.0468 146.161V112.003L55.1956 100.5Z" fill="url(#paint2_linear_866_816)"/>
<path d="M21.3375 106.704V152.131L55.4245 159.291V100.6L21.3375 106.704ZM35.6092 149.078L26.0554 147.084V111.869L35.6092 110.225V149.078ZM50.5887 152.131L39.6194 150.369V109.638L50.5887 107.76V152.131Z" fill="#341A6E"/>
<path d="M127.958 100L94.225 106.221V152L127.958 159.278L161.691 145.661V111.503L127.958 100Z" fill="url(#paint3_linear_866_816)"/>
<path d="M94.225 106.234V152.131L128.195 159.291V100.6L94.225 106.234ZM108.497 148.609L98.943 146.614V111.399L108.497 109.756V148.609ZM123.358 151.191L112.389 149.431V109.169L123.358 107.291V151.191Z" fill="#341A6E"/>
<path d="M200.733 100.6L167 106.821V152.6L200.733 159.761L234.584 146.262V112.103L200.733 100.6Z" fill="url(#paint4_linear_866_816)"/>
<path d="M167 106.821V152.131L201.087 159.291V100.6L167 106.821ZM181.39 149.196L171.836 147.2V111.986L181.39 110.343V149.196ZM196.251 151.779L185.283 150.017V109.756L196.251 107.878V151.779Z" fill="#341A6E"/>
<path d="M88.9331 164.349L55.2 170.454V216.233L88.9331 223.51L122.784 209.894V175.853L88.9331 164.349Z" fill="url(#paint5_linear_866_816)"/>
<path d="M55.2 170.449V216.227L89.2871 223.505V163.875L55.2 170.449ZM69.5896 212.823L60.0359 210.827V175.613L69.5896 173.97V212.823ZM84.4511 215.523L73.482 213.763V173.382L84.4511 171.505V215.523Z" fill="#341A6E"/>
<path d="M161.809 164.8L128.076 171.021V216.8L161.809 223.96L195.66 210.461V176.303L161.809 164.8Z" fill="url(#paint6_linear_866_816)"/>
<path d="M128.076 171.052V216.831L162.162 223.991V165.301L128.076 171.052ZM142.465 213.426L132.911 211.43V176.216L142.465 174.573V213.426ZM157.326 216.009L146.357 214.248V173.987L157.326 172.108V216.009Z" fill="#341A6E"/>
<defs>
<linearGradient id="paint0_linear_866_816" x1="56.0375" y1="66.9051" x2="123.622" y2="66.9051" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint1_linear_866_816" x1="744.993" y1="262.142" x2="1130.9" y2="262.142" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint2_linear_866_816" x1="22.1384" y1="640.04" x2="408.721" y2="640.04" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint3_linear_866_816" x1="511.167" y1="638.239" x2="897.074" y2="638.239" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint4_linear_866_816" x1="1001.67" y1="640.731" x2="1388.93" y2="640.731" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint5_linear_866_816" x1="249.167" y1="1025.72" x2="636.425" y2="1025.72" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
<linearGradient id="paint6_linear_866_816" x1="739.712" y1="1028.54" x2="1126.97" y2="1028.54" gradientUnits="userSpaceOnUse">
<stop stop-color="#B77AF4"/>
<stop offset="1" stop-color="#773ADC"/>
</linearGradient>
</defs>
</svg>`,
    name: 'logo:azure-aks-logo'
};