export const appGoogleGKELogoIcon = {
    data: `<svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
<path d="M128 251C126.31 251 124.619 250.572 123.097 249.716L23.0991 193.466C19.951 191.695 18.0022 188.363 18.0022 184.751V72.2518C18.0022 68.6393 19.951 65.3074 23.0991 63.5362L123.097 7.28632C126.141 5.57383 129.858 5.57383 132.903 7.28632L232.9 63.5362C236.049 65.3074 237.997 68.6393 237.997 72.2518V184.751C237.997 188.363 236.049 191.695 232.9 193.466L132.903 249.716C131.38 250.572 129.689 251 127.999 251H128ZM38.0022 178.903L128 229.527L217.998 178.903V78.1005L128 27.4756L38.0022 78.1005V178.903Z" fill="#34A853"/>
<path d="M28.0116 82.2536C24.516 82.2536 21.1229 80.4173 19.2866 77.1549C16.5791 72.3411 18.286 66.2436 23.0997 63.5361L123.097 7.28628C127.912 4.58004 134.008 6.28629 136.716 11.0994C139.423 15.9131 137.716 22.0106 132.903 24.7181L32.9047 80.968C31.3547 81.8398 29.671 82.2542 28.011 82.2542L28.0116 82.2536Z" fill="#EA4335"/>
<path d="M227.988 82.2535C226.328 82.2535 224.645 81.8398 223.095 80.9673L123.097 24.7174C118.284 22.0099 116.576 15.9124 119.284 11.0987C121.992 6.28559 128.088 4.57872 132.902 7.28558L232.9 63.5354C237.713 66.2429 239.421 72.3404 236.713 77.1541C234.877 80.4173 231.483 82.2529 227.988 82.2529V82.2535Z" fill="#EA4335"/>
<path d="M128 82.2516C122.477 82.2516 118 77.7748 118 72.2517V16.0018C118 10.4787 122.477 6.00183 128 6.00183C133.523 6.00183 138 10.4787 138 16.0018V72.2517C138 77.7748 133.523 82.2516 128 82.2516Z" fill="#EA4335"/>
<path d="M28.0021 194.751C26.2565 194.751 24.5121 194.294 22.954 193.383C19.8871 191.589 18.0021 188.304 18.0021 184.751V72.2516C18.0021 66.7285 22.479 62.2516 28.0021 62.2516C33.5252 62.2516 38.0021 66.7285 38.0021 72.2516V167.652L73.0976 147.91C77.9126 145.204 84.0082 146.91 86.7163 151.723C89.4238 156.537 87.717 162.634 82.9032 165.342L32.9046 193.467C31.3815 194.323 29.6909 194.751 28.0015 194.751L28.0021 194.751Z" fill="#FBBC04"/>
<path d="M227.998 194.751C226.308 194.751 224.619 194.323 223.095 193.466L173.096 165.341C168.283 162.634 166.576 156.536 169.283 151.723C171.992 146.909 178.087 145.202 182.902 147.91L217.997 167.651V72.2511C217.997 66.728 222.474 62.2511 227.997 62.2511C233.52 62.2511 237.997 66.728 237.997 72.2511V184.75C237.997 188.303 236.112 191.588 233.045 193.383C231.487 194.294 229.742 194.75 227.997 194.75L227.998 194.751Z" fill="#4285F4"/>
<mask id="mask0_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="18" y="6" width="220" height="246">
<path d="M128 251C126.31 251 124.619 250.572 123.098 249.716L23.0991 193.466C19.9503 191.695 18.0016 188.363 18.0016 184.751V72.2518C18.0016 68.6393 19.9503 65.3074 23.0991 63.5362L123.098 7.28634C126.141 5.57447 129.859 5.57447 132.902 7.28634L232.9 63.5356C236.049 65.3068 237.997 68.6387 237.997 72.2512V184.75C237.997 188.363 236.049 191.695 232.9 193.466L132.902 249.715C131.38 250.571 129.689 250.999 127.999 250.999L128 251ZM38.0015 178.902L128 229.526L217.999 178.902V78.0999L128 27.4757L38.0015 78.0999V178.902Z" fill="white"/>
</mask>
<g mask="url(#mask0_866_815)">
<path d="M-1.98842 211.628C-5.48403 211.628 -8.87715 209.792 -10.7134 206.529C-13.4209 201.715 -11.714 195.618 -6.90028 192.91L73.0983 147.91C77.9132 145.204 84.0089 146.91 86.717 151.724C89.4245 156.537 87.7176 162.635 82.9039 165.342L2.90532 210.342C1.35532 211.214 -0.328422 211.628 -1.98842 211.628V211.628Z" fill="#FBBC04"/>
<path d="M257.988 211.628C256.328 211.628 254.645 211.214 253.095 210.341L173.096 165.341C168.283 162.634 166.576 156.537 169.283 151.723C171.992 146.909 178.087 145.202 182.902 147.91L262.9 192.91C267.713 195.617 269.421 201.715 266.713 206.528C264.877 209.791 261.483 211.627 257.988 211.627V211.628Z" fill="#4285F4"/>
</g>
<mask id="mask1_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="117" y="6" width="121" height="77">
<path d="M227.989 82.2535C226.329 82.2535 224.645 81.8392 223.096 80.9673L123.098 24.718C118.284 22.0105 116.577 15.9131 119.285 11.1C121.993 6.28684 128.091 4.57935 132.903 7.28684L232.901 63.5361C237.715 66.2436 239.422 72.341 236.714 77.1542C234.878 80.4179 231.485 82.2535 227.989 82.2535Z" fill="white"/>
</mask>
<g mask="url(#mask1_866_815)">
<path d="M227.998 194.751C222.475 194.751 217.998 190.274 217.998 184.751V38.5018C217.998 32.9787 222.475 28.5018 227.998 28.5018C233.521 28.5018 237.998 32.9787 237.998 38.5018V184.751C237.998 190.274 233.521 194.751 227.998 194.751Z" fill="#4285F4"/>
</g>
<mask id="mask2_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="18" y="6" width="121" height="77">
<path d="M28.011 82.2536C24.5154 82.2536 21.1222 80.418 19.286 77.1542C16.5785 72.3405 18.2854 66.2436 23.0991 63.5361L123.098 7.28629C127.91 4.58005 134.008 6.28567 136.716 11.0994C139.423 15.9131 137.716 22.01 132.903 24.7175L32.9047 80.9667C31.3553 81.8386 29.671 82.253 28.0116 82.253L28.011 82.2536Z" fill="white"/>
</mask>
<g mask="url(#mask2_866_815)">
<path d="M28.0022 194.751C26.2566 194.751 24.5122 194.294 22.9541 193.383C19.8872 191.59 18.0022 188.304 18.0022 184.751V38.5018C18.0022 32.9787 22.4791 28.5018 28.0022 28.5018C33.5253 28.5018 38.0022 32.9787 38.0022 38.5018V167.653L73.0977 147.91C77.9127 145.204 84.0083 146.91 86.7164 151.723C89.4239 156.537 87.7171 162.635 82.9033 165.342L32.9047 193.467C31.3816 194.323 29.691 194.751 28.0016 194.751L28.0022 194.751Z" fill="#FBBC04"/>
</g>
<path d="M177.99 110.378C176.33 110.378 174.646 109.964 173.096 109.092L128 83.7248L82.9038 109.092C78.0901 111.8 71.9932 110.094 69.2851 105.278C66.5776 100.465 68.2845 94.3679 73.0982 91.6604L123.098 63.5355C126.141 61.823 129.859 61.823 132.903 63.5355L182.903 91.6604C187.716 94.3679 189.424 100.465 186.716 105.278C184.88 108.542 181.487 110.378 177.991 110.378L177.99 110.378Z" fill="#EA4335"/>
<path d="M128.009 194.753C124.514 194.753 121.121 192.916 119.284 189.654C116.577 184.84 118.284 178.743 123.097 176.035L168 150.778V100.377C168 94.8534 172.477 90.3766 178 90.3766C183.523 90.3766 188 94.8534 188 100.377V156.626C188 160.238 186.051 163.57 182.903 165.341L132.904 193.466C131.354 194.338 129.669 194.753 128.009 194.753Z" fill="#4285F4"/>
<path d="M128 194.751C126.311 194.751 124.621 194.323 123.097 193.466L73.0976 165.341C69.9495 163.57 68.0008 160.238 68.0008 156.626V100.377C68.0008 94.8534 72.4776 90.3766 78.0008 90.3766C83.5239 90.3766 88.0007 94.8534 88.0007 100.377V150.778L118 167.653V128.501C118 122.978 122.477 118.502 128 118.502C133.523 118.502 138 122.978 138 128.501V184.751C138 188.304 136.115 191.589 133.048 193.383C131.49 194.294 129.745 194.751 128 194.751Z" fill="#FBBC04"/>
<path d="M128.009 138.503C124.514 138.503 121.121 136.667 119.284 133.405C116.577 128.591 118.284 122.493 123.097 119.786L173.097 91.661C177.912 88.9541 184.007 90.661 186.715 95.4741C189.423 100.288 187.716 106.385 182.902 109.093L132.903 137.218C131.353 138.09 129.669 138.504 128.009 138.504V138.503Z" fill="#4285F4"/>
<mask id="mask3_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="117" y="146" width="72" height="49">
<path d="M128.009 194.753C124.514 194.753 121.121 192.917 119.284 189.653C116.577 184.84 118.284 178.743 123.097 176.035L173.097 147.91C177.91 145.204 184.007 146.91 186.715 151.723C189.422 156.537 187.715 162.634 182.902 165.342L132.902 193.466C131.353 194.338 129.669 194.753 128.009 194.753Z" fill="white"/>
</mask>
<g mask="url(#mask3_866_815)">
<path d="M128 251C122.477 251 118 246.523 118 241V128.501C118 122.978 122.477 118.501 128 118.501C133.523 118.501 138 122.978 138 128.501V241C138 246.523 133.523 251 128 251Z" fill="#FBBC04"/>
</g>
<path d="M186.715 95.474C184.007 90.6603 177.91 88.9547 173.097 91.6609L128 117.028V138.503C129.086 138.501 130.161 138.323 131.183 137.98C131.212 137.97 131.241 137.959 131.269 137.949C131.439 137.89 131.607 137.828 131.773 137.761C131.861 137.725 131.946 137.685 132.033 137.646C132.139 137.6 132.245 137.555 132.349 137.505C132.49 137.436 132.629 137.363 132.767 137.287C132.824 137.256 132.883 137.228 132.941 137.195L132.996 137.164L182.901 109.092C187.715 106.385 189.422 100.287 186.714 95.474H186.715Z" fill="#4285F4"/>
<path d="M136.716 67.3492C134.008 62.5348 127.91 60.8292 123.098 63.5361L73.0983 91.661C69.9495 93.4323 68.0008 96.7641 68.0008 100.377C68.0008 103.989 69.9495 107.321 73.0983 109.092L123.001 137.163L123.063 137.198C123.114 137.227 123.166 137.252 123.218 137.28C123.362 137.359 123.507 137.436 123.653 137.507C123.749 137.553 123.847 137.595 123.944 137.638C124.039 137.68 124.134 137.725 124.231 137.764C124.387 137.828 124.547 137.886 124.707 137.942C124.744 137.955 124.782 137.97 124.82 137.983C125.841 138.325 126.916 138.503 128.001 138.504V117.029L98.3988 100.377L132.903 80.9685C137.717 78.2611 139.424 72.1636 136.716 67.3505L136.716 67.3492Z" fill="#EA4335"/>
<mask id="mask4_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="68" y="90" width="21" height="49">
<path d="M78.0008 138.501C72.4783 138.501 68.0008 134.023 68.0008 128.501V100.376C68.0008 94.8535 72.4776 90.376 78.0008 90.376C83.5239 90.376 88.0007 94.8535 88.0007 100.376V128.501C88.0007 134.023 83.5239 138.501 78.0008 138.501Z" fill="white"/>
</mask>
<g mask="url(#mask4_866_815)">
<path d="M127.991 138.503C126.33 138.503 124.647 138.09 123.097 137.217L23.099 80.9672C18.2859 78.2597 16.5784 72.1622 19.2859 67.3485C21.994 62.5354 28.0909 60.8279 32.9046 63.5354L132.902 119.785C137.716 122.493 139.423 128.59 136.716 133.404C134.879 136.667 131.486 138.503 127.991 138.503V138.503Z" fill="#EA4335"/>
</g>
<mask id="mask5_866_815" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="117" y="62" width="72" height="49">
<path d="M177.99 110.378C176.33 110.378 174.646 109.964 173.097 109.092L123.097 80.9667C118.284 78.2592 116.577 72.1617 119.284 67.3486C121.992 62.5355 128.091 60.828 132.902 63.5355L182.902 91.6604C187.715 94.3679 189.422 100.465 186.715 105.279C184.879 108.542 181.485 110.378 177.99 110.378Z" fill="white"/>
</mask>
<g mask="url(#mask5_866_815)">
<path d="M128.009 138.503C124.514 138.503 121.121 136.667 119.284 133.405C116.577 128.591 118.284 122.494 123.097 119.786L223.095 63.5362C227.91 60.8299 234.007 62.5362 236.714 67.3493C239.422 72.163 237.715 78.2605 232.901 80.968L132.903 137.218C131.353 138.09 129.669 138.504 128.009 138.504V138.503Z" fill="#4285F4"/>
</g>
</svg>`,
    name: 'logo:google-gke-logo'
};