import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject, EMPTY, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

import { GetToken } from '@api-open';
import { AlertifyService } from '@shared/services';
import { AuthService } from '@shared/services/auth.service';

import { BYPASS_ERROR_400 } from './bypass-error.context-token';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private updateTokensInProgress$ = new BehaviorSubject(false);
  private updateTokenProcessFinished$ = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private alertify: AlertifyService,
  ) {}

  refreshTokens(): Observable<GetToken> {
    return this.authService
      .refreshToken({
        token: this.authService.getToken(),
        refreshToken: this.authService.getRefreshToken(),
      })
      .pipe(
        tap((token) => {
          this.authService.storeTokens(token);
        }),
      );
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorBody = error.error;

        if (request.responseType === 'text') {
          try {
            errorBody = JSON.parse(errorBody);
          } catch {
            /* empty */
          }
        }

        switch (error.status) {
          case 403:
            if (error.headers.get('token-expired') === 'true') {
              this.logout();
            }
            this.updateTokensInProgress$
              .pipe(
                take(1),
                filter((isLoading) => !isLoading),
                tap(() => {
                  this.alertify.informative(errorBody.detail);
                  return this.updateTokensInProgress$.next(true);
                }),
                switchMap(() => this.refreshTokens()),
                catchError(() => {
                  this.logout();
                  return EMPTY;
                }),
              )
              .subscribe((authData) => {
                if (!authData) {
                  this.logout();
                }
                this.alertify.informative('The page will be reloaded in 2 seconds');

                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              });

            return EMPTY;
          case 401:
            if (error.headers.get('token-expired') === 'true') {
              this.updateTokensInProgress$
                .pipe(
                  take(1),
                  filter((isLoading) => !isLoading),
                  tap(() => this.updateTokensInProgress$.next(true)),
                  switchMap(() => this.refreshTokens()),
                  catchError(() => {
                    this.logout();
                    return EMPTY;
                  }),
                )
                .subscribe(
                  (authData) => {
                    if (!authData) {
                      this.logout();
                    }
                    this.updateTokensInProgress$.next(false);
                    this.updateTokenProcessFinished$.next(true);
                  },
                  () => {
                    this.updateTokensInProgress$.next(false);
                    this.updateTokenProcessFinished$.next(true);
                    this.logout();
                  },
                );

              return this.updateTokenProcessFinished$.pipe(
                take(1),
                filter((tokenSuccessfullyUpdated) => tokenSuccessfullyUpdated),
                switchMap(() => {
                  const updatedRequest = request.clone({
                    headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken()),
                  });
                  return next
                    .handle(updatedRequest)
                    .pipe(catchError((errorResponse: HttpErrorResponse) => throwError(errorResponse.message)));
                }),
              );
            }
            this.logout();
            return throwError(error.message);
          case 500:
            Sentry.captureException(new Error(`500 - ${JSON.stringify(error)}`));
            return throwError('500 - Server error');
          default:
            if (request.context.get(BYPASS_ERROR_400)) {
              return throwError(error);
            }
            Sentry.captureException(new Error(`400 - ${JSON.stringify(error)}`));
            return throwError(errorBody?.detail || error.message || 'Error occurred');
        }
      }),
    );
  }

  private logout(): void {
    this.authService.logout({ saveLocationForRedirect: true });
  }
}
