import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { setConfig } from './config';
import { environment } from './environments/environment';
import * as sentryRelease from './sentry-release.json';

fetch('configs/config.json', { cache: 'no-cache' })
  .then((response) => response.json())
  .then((config) => {
    Sentry.init({
      environment: config.environment,
      dsn: 'https://1d9b25798e50abef39e3ce43d5634c0c@o4507069013688320.ingest.us.sentry.io/4507069030727680',
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      release: sentryRelease.release,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      tracePropagationTargets: [],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    setConfig(config);

    if (environment.production) {
      enableProdMode();
    }

    return platformBrowserDynamic().bootstrapModule(AppModule);
  })
  .catch((err) => {
    console.error(err);
  });
